<template>
  <b-card no-body>
    <h3 class="light-blue col-md-12 mt-1">{{ $t("confirm_beneficial_owner") }}</h3>
    <!-- <span class="primary">{{ $t("confirm_beneficial_owner_subtitle") }}</span> -->
    <b-card-text class="mt-2">
      <validation-observer
        ref="confirmBeneficialOwner"
        name="ConfirmBeneficialOwner"
      >
        <b-row class="col-md-12 pr-0 mr-0" v-if="errors.length > 0">
          <div class="alert alert-danger col-md-12 pr-0">
            <div class="p-2">
              <div v-for="(err, index) in errors" :key="index">
                <b-avatar variant="light-danger" size="45">
                  <feather-icon size="21" icon="AlertTriangleIcon" />
                </b-avatar>
                {{ err }}
              </div>
            </div>
          </div>
        </b-row>

        <b-form @submit="onSubmit" @reset="onReset">
          <b-card class="col-md-12">
            <span class="beneficial-owner-title row col-md-12">{{
              $t("source_of_funds")
            }}</span>
            <br />
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="source-of-funds"
                  :label="$t('source_of_funds') + ':'"
                  label-for="domicile-country"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="SourceOfFunds"
                    rules="required"
                  >
                    <b-form-select
                      id="source-of-funds"
                      v-model.trim="source_of_funds"
                      :options="[
                        {
                          text: $t('select_one'),
                          value: null,
                          disabled: true,
                        },
                        { text: $t('employed'), value: 1 },
                        { text: $t('self-employed'), value: 2 },
                        { text: $t('retired'), value: 3 },
                        { text: $t('etc'), value: 4 },
                      ]"
                      :state="errors.length > 0 ? false : null"
                      required
                      :disabled="disableInputs"
                    ></b-form-select>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="planned-investment-amount"
                  :label="$t('planned_investment_amount') + ':'"
                  label-for="planned-investment-amount"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="PlannedInvestmentAmount"
                    rules="required"
                  >
                    <b-form-select
                      id="planned-investment-amount"
                      v-model.trim="investment_amount"
                      :options="[
                        {
                          text: $t('select_one'),
                          value: null,
                          disabled: true,
                        },
                        { value: 1, text: $t('les_than_10000') },
                        { value: 2, text: $t('10-50.000') },
                        { value: 3, text: $t('50-100.000') },
                        { value: 4, text: $t('100.000+') },
                      ]"
                      :state="errors.length > 0 ? false : null"
                      required
                      :disabled="disableInputs"
                    ></b-form-select>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <br /><br />
            <br />
            <span class="beneficial-owner-title row col-md-12">{{
              $t("beneficial_owner")
            }}</span>

            <span class="row col-md-12">
              {{ $t("beneficial_owner_subtitle") }}
            </span>
            <br />
            <b-row m-0 p-0>
              <b-col cols="12" xl="12" md="12" lg="12">
                <b-button
                  class="mb-2"
                  :variant="
                    beneficial_owner_selected_option == 1
                      ? 'primary'
                      : 'outline-primary'
                  "
                  @click="changeBeneficialOwnerOption(1)"
                  >{{ $t("i_am_beneficial_owner") }}
                </b-button>
                <b-button
                  class="mb-2 ml-xl-1 ml-lg-1 ml-md-1 ml-sm-0"
                  @click="changeBeneficialOwnerOption(2)"
                  :variant="
                    beneficial_owner_selected_option == 2
                      ? 'primary'
                      : 'outline-primary'
                  "
                >
                  {{ $t("following_person_is_beneficial_owner") }}
                </b-button>
                <b-row class="col-md-12 font-weight-bolder">
                  {{ $t("determination_of_owner") }}
                </b-row>

                <div
                  class="others-as-beneficial-owner-box p-1 pt-2"
                  v-if="beneficial_owner_selected_option == 1"
                >
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio
                      v-model.trim="beneficial_radio"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      :value="beneficial_radio == 1 ? 1 : 0"
                    >
                      <span v-if="user.first_name">{{ user.first_name }} </span>
                      <span v-if="user.last_name">{{ user.last_name }} </span>
                      <span v-if="user.address">, {{ user.address }} </span>
                      <span v-if="user.address_nr"
                        >{{ user.address_nr }},
                      </span>

                      <span v-if="user.zip"> {{ user.zip }} </span>
                      <span v-if="user.city"> {{ user.city }} </span>
                      <span v-if="user.birthday">
                        , {{ $t("born") }} {{ user.birthday  }}
                      </span>
                      <span v-if="user.nationality">
                        ,
                        <span class="text-capitalize">{{
                          $t("nationality")
                        }}</span>
                        : {{ user.nationality }}
                      </span>
                    </b-form-radio>
                  </b-form-group>
                </div>
                <br />

                <div v-if="beneficial_owner_selected_option == 2">
                  <div
                    v-for="(item, index) in other_as_beneficial_owner_items"
                    :key="index"
                    class="others-as-beneficial-owner-box"
                  >
                    <div>
                      <b-row m-0 p-0>
                        <div class="col-md-12" align="end">
                          <b-btn
                            @click="removeBeneficialOwner(index)"
                            variant="danger"
                            align="end"
                            size="sm"
                          >
                            <feather-icon icon="XIcon"></feather-icon>
                          </b-btn>
                        </div>
                        <b-col cols="12" xl="6" md="6" lg="6">
                          <b-form-group :label="$t('first_name')">
                            <validation-provider
                              #default="{ errors }"
                              :name="'first_name'"
                              rules="required"
                            >
                              <b-form-input
                                :disabled="disableInputs"
                                v-model.trim="item.first_name"
                                :id="'first_name_' + index"
                              ></b-form-input>
                              <small class="text-danger">{{ $t(errors[0]) }}</small>
                            </validation-provider>
                          </b-form-group>
                          <b-form-group :label="$t('last_name')">
                            <validation-provider
                              #default="{ errors }"
                              :name="'last_name'"
                              rules="required"
                            >
                              <b-form-input
                                :disabled="disableInputs"
                                v-model.trim="item.last_name"
                                :id="'last_name_' + index"
                              ></b-form-input>
                              <small class="text-danger">{{ $t(errors[0]) }}</small>
                            </validation-provider>
                          </b-form-group>
                          <b-form-group :label="$t('birthday')">
                            <validation-provider
                              #default="{ errors }"
                              :name="'birthday'"
                              rules="required"
                            >
                              <b-form-input
                                :disabled="disableInputs"
                                v-model.trim="item.birthday"
                                type="date"
                                :id="'birthday_' + index"
                              ></b-form-input>
                              <small class="text-danger">{{ $t(errors[0]) }}</small>
                            </validation-provider>
                          </b-form-group>
                          <b-form-group :label="$t('nationality')">
                            <validation-provider
                              #default="{ errors }"
                              :name="'nationality'"
                              rules="required"
                            >
                              <b-form-select
                                :disabled="disableInputs"
                                :options="
                                  $store.state.app.language == 'en'
                                    ? countries
                                    : countries_de
                                "
                                v-model.trim="item.nationality"
                                type="date"
                                :id="'nationality_' + index"
                              ></b-form-select>
                              <small class="text-danger">{{ $t(errors[0]) }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" xl="6" md="6" lg="6">
                          <b-row>
                            <b-col cols="12" xl="9" md="9" lg="9">
                              <b-form-group :label="$t('street_name')">
                                <validation-provider
                                  #default="{ errors }"
                                  :name="'street_name'"
                                  rules="required"
                                >
                                  <b-form-input
                                    :disabled="disableInputs"
                                    v-model.trim="item.street"
                                    :id="'street_name_' + index"
                                  ></b-form-input>
                                  <small class="text-danger">{{
                                    $t(errors[0])
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col  cols="12" xl="3" md="3" lg="3">
                              <b-form-group :label="$t('street_nr')">
                                <validation-provider
                                  #default="{ errors }"
                                  :name="'street_nr'"
                                  rules="required"
                                >
                                  <b-form-input
                                    :disabled="disableInputs"
                                    type="number"
                                    v-model.trim="item.street_nr"
                                    :id="'street_nr_' + index"
                                  ></b-form-input>
                                  <small class="text-danger">{{
                                    $t(errors[0])
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12" xl="4" md="4" lg="4">
                              <b-form-group :label="$t('zip')">
                                <validation-provider
                                  #default="{ errors }"
                                  :name="'zip_code'"
                                  rules="required"
                                >
                                  <b-form-input
                                    :disabled="disableInputs"
                                    type="number"
                                    v-model.trim="item.zip"
                                    :id="'zip_code_' + index"
                                  ></b-form-input>
                                  <small class="text-danger">{{
                                    $t(errors[0])
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" xl="8" md="8" lg="8">
                              <b-form-group :label="$t('city')">
                                <validation-provider
                                  #default="{ errors }"
                                  :name="'city'"
                                  rules="required"
                                >
                                  <b-form-input
                                    :disabled="disableInputs"
                                    v-model.trim="item.city"
                                    :id="'city_' + index"
                                  ></b-form-input>
                                  <small class="text-danger">{{
                                    $t(errors[0])
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>

                          <b-form-group :label="$t('country_name')">
                            <validation-provider
                              #default="{ errors }"
                              :name="'country'"
                              rules="required"
                            >
                              <b-form-select
                                :disabled="disableInputs"
                                :options="
                                  $store.state.app.language == 'en'
                                    ? countries
                                    : countries_de
                                "
                                v-model.trim="item.country"
                                :id="'country_' + index"
                              ></b-form-select>
                              <small class="text-danger">{{ $t(errors[0]) }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <b-button
                    class="mt-1"
                    variant="outline-primary"
                    @click="addPersonInBeneficialOwner"
                    >+
                    {{ $t("additional_beneficial_owner") }}
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-col cols="12">
                <b-form-group id="confirmation_checkbox">
                  <validation-provider
                    #default="{ errors }"
                    name="confirmation_checkbox"
                    rules="required"
                  >
                    <b-form-checkbox
                      v-model.trim="confirmation_checkbox"
                      name="confirmation-checkbox"
                      value="true"
                      unchecked-value="false"
                      :state="
                        errors.length > 0 || confirmation_checkbox == 'false'
                          ? false
                          : null
                      "
                      required
                    >
                      {{ $t("confirmation_beneficial_owner_checkbox_text") }}
                    </b-form-checkbox>

                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12" class="d-flex justify-content-end">
                <b-button
                  variant="primary"
                  @click="saveBeneficialOwnerForm()"
                  >{{ $t("submit") }}</b-button
                >
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-card-text>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { VueAutosuggest } from "vue-autosuggest";
import moment from "moment";
import i18n from "@/libs/i18n";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BFormDatepicker,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckbox,
  BAlert,
  BInputGroupPrepend,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  name: "ConfirmBeneficialOwner",
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormDatepicker,
    BFormRadio,
    BFormRadioGroup,
    BAlert,
    BInputGroupPrepend,
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
  },
  data() {
    return {
      confirmation_checkbox: null,
      disableInputs: false,
      errors: [],
      beneficial_radio: "1",
      selected: true,
      user: {
        first_name: "",
        last_name: "",
        address: "",
        city: "",
        zip: "",
        birthday: "",
        nationality: "",
      },
      source_of_funds: null,
      source_of_funds_items: [
        { value: null, text: i18n.t("please_select") },
        { value: 1, text: i18n.t("employeed") },
        { value: 2, text: i18n.t("self-employed") },
        { value: 3, text: i18n.t("retired") },
        { value: 4, text: i18n.t("etc") },
      ],
      investment_amount: null,
      investment_amount_items: [
        { value: null, text: this.$t("please_select") },
        { value: 1, text: this.$t("les_than_10000") },
        { value: 2, text: this.$t("10-50.000") },
        { value: 3, text: this.$t("50-100.000") },
        { value: 4, text: this.$t("100.000+") },
      ],
      nationalities: [
        { value: null, text: this.$t("please_select") },
        { value: "Switzerland", text: "Switzerland" },
        { value: "Albanian", text: "Albanian" },
        { value: "Macedonian", text: "Macedonian" },
        { value: "German", text: "German" },
      ],
      beneficial_owner_selected_option: 1,
      beneficial_owner_selected_option_main_user: this.$store.state.auth.user,
      other_as_beneficial_owner_items: [],
      countries: [{ value: null, text: this.$t("please_select") }],
      countries_de: [{ value: null, text: this.$t("please_select") }],
      checkbox: true,
    };
  },
  created() {
    this.$http.post("/countries").then((res) => {
      res.data.value.map((item) => {
        this.countries.push({
          value: item.id,
          text: item.name,
        });
        this.countries_de.push({
          value: item.id,
          text: item.name_de,
        });
      });
    });
    this.getUser();
  },
  methods: {
    getUser() {
      this.$http.post("/buyer/show", {}).then((res) => {
        if (res.data.value) {
          this.user.id = res.data.value.id;
          this.user.first_name = res.data.value.first_name;
          this.user.last_name = res.data.value.last_name;
          this.user.address = res.data.value.address;
          this.user.zip = res.data.value.zip;
          this.user.address_nr = res.data.value.address_nr;

          this.user.city = res.data.value.city;

          this.user.birthday = res.data.value.birthday;
          this.user.nationality = res.data.value.nationality;
       
          this.source_of_funds = res.data.value.source_of_funds;
          this.investment_amount = res.data.value.investment_amount;
          if (this.investment_amount || this.investment_amount) {
            this.confirmation_checkbox = "true";
            if (
              this.beneficial_owner_selected_option_main_user.owners.length < 1
            ) {
              this.beneficial_radio = 1;
            }
          }

          this.beneficial_owner_selected_option_main_user = res.data.value;

          if (
            this.beneficial_owner_selected_option_main_user.owners.length > 0
          ) {
            this.beneficial_radio = 0;
            this.beneficial_owner_selected_option = 2;
            this.confirmation_checkbox = "true";
            this.source_of_funds =
              this.beneficial_owner_selected_option_main_user.owners[0].source_of_funds;

            this.investment_amount =
              this.beneficial_owner_selected_option_main_user.owners[0].investment_amount;
            this.beneficial_owner_selected_option_main_user.owners.map(
              (item) => {
                this.other_as_beneficial_owner_items.push({
                  first_name: item.first_name,
                  last_name: item.last_name,
                  birthday: item.birthday,
                  nationality: item.nationality,
                  street: item.address,
                  street_nr: item.address_nr,
                  zip: item.zip,
                  city: item.city,
                  country: item.country_id,
                });
              }
            );
          }
        }
      });
    },
    addPersonInBeneficialOwner() {
      if (this.other_as_beneficial_owner_items.length < 5) {
        this.other_as_beneficial_owner_items.push({
          first_name: "",
          last_name: "",
          birthday: "",
          nationality: null,
          street: "",
          zip: "",
          city: "",
          country: null,
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("maximum_5_beneficial_owner"),
            icon: "SuccessIcon",
            variant: "success",
          },
        });
      }
    },
    removeBeneficialOwner(index) {
      if (this.other_as_beneficial_owner_items.length > 1) {
        this.other_as_beneficial_owner_items.splice(index, 1);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("minimum_1_beneficial_owner"),
            icon: "DangerIcon",
            variant: "danger",
          },
        });
      }
    },
    changeBeneficialOwnerOption(type) {
      this.beneficial_owner_selected_option = type;
      if (this.other_as_beneficial_owner_items.length < 1) {
        this.addPersonInBeneficialOwner();
      }
    },
    saveBeneficialOwnerForm() {
      this.checkbox = true;
      this.errors = [];

      this.$refs.confirmBeneficialOwner.validate().then((success) => {
        if (success && this.confirmation_checkbox != "false") {
          this.disableInputs = true;
          const formSubmissionData = {
            source_of_funds: this.source_of_funds,
            investment_amount: this.investment_amount,
            beneficial_owner_selected_option:
              this.beneficial_owner_selected_option,
            beneficial_owner_selected_option_main_user:
              this.beneficial_owner_selected_option_main_user,
            other_as_beneficial_owner_items:
              this.beneficial_owner_selected_option == 1
                ? null
                : this.other_as_beneficial_owner_items,
          };
          let data = {};
          data = { ...formSubmissionData };
    
          this.$http
            .post("/buyer/create-beneficial-owner", data)
            .then((res) => {
              let mess = "";
              let icon = "";
              let variant = "";
              if (res && res.data.status == 500) {
                mess = res.data.message;
                icon = "ErrorIcon";
                variant = "error";
              } else {
                mess = "confirm_beneficial_owner_successfully";
                icon = "SuccessIcon";
                variant = "success";
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t(mess),
                  icon: icon,
                  variant: variant,
                },
              });
              this.$router.push("/");
            });
       
       
       
       } else {
          const el = document.getElementsByClassName("is-invalid");
          if (el) {
            setTimeout(function () {
              el[0].scrollIntoView({ behavior: "smooth", block: "center" });
            }, 500);
          } else if (this.errors.length > 0) {
            const err = document.getElementById("alert-errors");
            setTimeout(function () {
              err.scrollIntoView({ behavior: "smooth", block: "center" });
            }, 500);
          }
        }
        this.disableInputs = false;
      });
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
    },
  },
};
</script>

<style scoped>
.beneficial-owner-title {
  color: #1586c4;
  border-left: 1px solid #1586c4;
  padding-left: 15px;
  font-weight: bolder;
}

.others-as-beneficial-owner-box {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 20px;
  margin-top: 20px;
}
</style>
