var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-card',[_c('b-card-header',[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('fill_online_form_page_title')))])]),_c('b-card-body',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitOnlineFormData.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"mb-4 w-100",attrs:{"label":_vm.$t('contracting_partner'),"label-for":"contracting_partner"}},[_c('validation-provider',{attrs:{"name":"contracting partner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"contracting_partner","required":"","rows":"3","value":_vm.CompanyData.name + ' ' +
                                    _vm.CompanyData.legal_Form + ' ' +
                                    _vm.CompanyData.address + ' ' +
                                    _vm.CompanyData.address_nr + ' ' +
                                    _vm.CompanyData.zip + ' ' +
                                    _vm.CompanyData.country.name + ' ',"disabled":"true"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1),_c('b-form-group',{attrs:{"disabled":_vm.disableAllInputs}},[_c('b-form-checkbox',{staticClass:"mb-2",attrs:{"value":"answer1"},model:{value:(_vm.submitData.question1),callback:function ($$v) {_vm.$set(_vm.submitData, "question1", $$v)},expression:"submitData.question1"}},[_vm._v(" "+_vm._s(_vm.$t('checkbox_1'))+" ")]),_c('b-form-checkbox',{staticClass:"mb-2",attrs:{"value":"answer2"},model:{value:(_vm.submitData.question1),callback:function ($$v) {_vm.$set(_vm.submitData, "question1", $$v)},expression:"submitData.question1"}},[_vm._v(" "+_vm._s(_vm.$t('checkbox_2'))+" ")]),_c('b-form-checkbox',{attrs:{"value":"answer3 "},model:{value:(_vm.submitData.question1),callback:function ($$v) {_vm.$set(_vm.submitData, "question1", $$v)},expression:"submitData.question1"}},[_vm._v(" "+_vm._s(_vm.$t('checkbox_3'))+" ")])],1),_c('div',{staticClass:"add_input_container w-100"},[_vm._l((_vm.submitData.additionalUsers),function(additionalField,index){return _c('div',{key:index,staticClass:"my-5"},[_c('b-form-group',{attrs:{"label":_vm.$t('first_name'),"label-for":'new_first_name_' + index}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":'new_first_name_' + index,"required":"","disabled":_vm.disableAllInputs},model:{value:(additionalField.firstName),callback:function ($$v) {_vm.$set(additionalField, "firstName", $$v)},expression:"additionalField.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('last_name'),"label-for":'new_last_name_' + index}},[_c('validation-provider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":'new_last_name_' + index,"required":"","disabled":_vm.disableAllInputs},model:{value:(additionalField.lastName),callback:function ($$v) {_vm.$set(additionalField, "lastName", $$v)},expression:"additionalField.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('actual_address'),"label-for":'new_actual_address_' + index}},[_c('validation-provider',{attrs:{"name":"Actual address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":'new_actual_address_' + index,"required":"","disabled":_vm.disableAllInputs},model:{value:(additionalField.actualAddress),callback:function ($$v) {_vm.$set(additionalField, "actualAddress", $$v)},expression:"additionalField.actualAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)],1)}),(!_vm.disableAllInputs)?_c('div',{staticClass:"my-3"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.FilladditionalUsersArray}},[_vm._v(_vm._s(_vm.$t('add_new')))])],1):_vm._e()],2),_c('h4',[_vm._v(_vm._s(_vm.$t('fiduciary_holding_assets')))]),_c('b-form-group',{staticClass:"mt-2 mb-2",attrs:{"label":"Is a third person the beneficial owner of the assets held in the account/securities account?"}},[_c('b-form-radio-group',{attrs:{"name":"fiduciary-holding","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.submitData.question2),callback:function ($$v) {_vm.$set(_vm.submitData, "question2", $$v)},expression:"submitData.question2"}},[_c('b-form-radio',{staticClass:"mt-1",attrs:{"name":"k1","value":"no"}},[_vm._v(_vm._s(_vm.$t('no')))]),_c('br'),_c('b-form-radio',{staticClass:"mt-1",attrs:{"name":"k1","value":"yes"}},[_vm._v(_vm._s(_vm.$t('yes')))])],1)],1),(_vm.submitData.question2 == 'yes')?[_c('div',{staticClass:"container mt-5"},[_c('h2',{staticClass:"mb-2"},[_vm._v("Establishment of the Beneficial Owner's Identity")]),_c('b-form-group',{staticClass:"mb-4 w-100",attrs:{"label":_vm.$t('contracting_partner'),"label-for":"contracting_partner"}},[_c('validation-provider',{attrs:{"name":"contracting partner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"contracting_partner","required":"","rows":"3","value":_vm.CompanyData.name + ' ' +
                                            _vm.CompanyData.legal_Form + ' ' +
                                            _vm.CompanyData.address + ' ' +
                                            _vm.CompanyData.address_nr + ' ' +
                                            _vm.CompanyData.zip + ' ' +
                                            _vm.CompanyData.country.name + ' ',"disabled":"true"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,739984669)})],1),_c('b-form-group',{staticClass:"mt-2 mb-4",attrs:{"label":"The contracting partner herewith declares, (tick the appropriate box)"}},[_c('b-form-radio-group',{attrs:{"name":"declaration","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.submitData.question4),callback:function ($$v) {_vm.$set(_vm.submitData, "question4", $$v)},expression:"submitData.question4"}},[_c('b-form-radio',{staticClass:"mt-1",attrs:{"name":"d2","value":"contracting_party"}},[_vm._v("that the contracting party is the beneficial owner of the assets involved ")]),_c('br'),_c('b-form-radio',{staticClass:"mt-1",attrs:{"name":"d2","value":"following_person"}},[_vm._v("that the following person(s) is/are the beneficial owner(s) of the assets involved: ")])],1)],1),(_vm.submitData.question4 == 'following_person')?_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('last_name'),"label-for":"beneficial_owner_last_name"}},[_c('validation-provider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"beneficial_owner_last_name","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.submitData.beneficialOwner.lastName),callback:function ($$v) {_vm.$set(_vm.submitData.beneficialOwner, "lastName", $$v)},expression:"submitData.beneficialOwner.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,621825611)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('fist_name'),"label-for":"beneficial_owner_last_name"}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"beneficial_owner_fist_name","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.submitData.beneficialOwner.firstName),callback:function ($$v) {_vm.$set(_vm.submitData.beneficialOwner, "firstName", $$v)},expression:"submitData.beneficialOwner.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,1921781657)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('birthday'),"label-for":"beneficial_owner_bod"}},[_c('validation-provider',{attrs:{"name":"Date of Birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"beneficial_owner_bod","required":"","type":"date","disabled":_vm.disableAllInputs},model:{value:(_vm.submitData.beneficialOwner.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.submitData.beneficialOwner, "dateOfBirth", $$v)},expression:"submitData.beneficialOwner.dateOfBirth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,4264729279)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('nationality'),"label-for":"beneficial_owner_nationality"}},[_c('validation-provider',{attrs:{"name":"Nationality","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"beneficial_owner_nationality","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.submitData.beneficialOwner.nationality),callback:function ($$v) {_vm.$set(_vm.submitData.beneficialOwner, "nationality", $$v)},expression:"submitData.beneficialOwner.nationality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,1960137140)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('address'),"label-for":"beneficial_owner_address"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"beneficial_owner_address","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.submitData.beneficialOwner.address),callback:function ($$v) {_vm.$set(_vm.submitData.beneficialOwner, "address", $$v)},expression:"submitData.beneficialOwner.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,3987648244)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('country'),"label-for":"beneficial_owner_country"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"beneficial_owner_country","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.submitData.beneficialOwner.country),callback:function ($$v) {_vm.$set(_vm.submitData.beneficialOwner, "country", $$v)},expression:"submitData.beneficialOwner.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,499080948)})],1)],1):_vm._e()],1)]:(_vm.submitData.question2 == 'no')?_c('b-form-group',{staticClass:"mt-2 mb-4",attrs:{"label":_vm.$t('radio_text_2')}},[_c('b-form-radio-group',{attrs:{"name":"manage-account","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.submitData.question3),callback:function ($$v) {_vm.$set(_vm.submitData, "question3", $$v)},expression:"submitData.question3"}},[_c('b-form-radio',{staticClass:"mt-1",attrs:{"name":"d2","value":"no"}},[_vm._v(_vm._s(_vm.$t('no')))]),_c('br'),_c('b-form-radio',{staticClass:"mt-1",attrs:{"name":"d2","value":"yes"}},[_vm._v(_vm._s(_vm.$t('yes')))])],1)],1):_vm._e(),(_vm.submitData.question2 == 'no' && _vm.submitData.question3 == 'no')?_c('div',{staticClass:"mb-5"},[_c('b-form-group',{attrs:{"label":_vm.$t('last_name'),"label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.submitData.accountManage.firstName),callback:function ($$v) {_vm.$set(_vm.submitData.accountManage, "firstName", $$v)},expression:"submitData.accountManage.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,3094683)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('first_name'),"label-for":"first_name"}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.submitData.accountManage.lastName),callback:function ($$v) {_vm.$set(_vm.submitData.accountManage, "lastName", $$v)},expression:"submitData.accountManage.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,2676814107)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('actual_address'),"label-for":"actual_address"}},[_c('validation-provider',{attrs:{"name":"Actual address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"actual_address","required":"","disabled":_vm.disableAllInputs},model:{value:(_vm.submitData.accountManage.address),callback:function ($$v) {_vm.$set(_vm.submitData.accountManage, "address", $$v)},expression:"submitData.accountManage.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,786310949)})],1)],1):_vm._e(),_c('b-form-group',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('register_3a_text1'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('register_3a_text2'))+" ")])]),_c('b-button',{staticClass:"mt-4",attrs:{"type":"submit","variant":"primary"}},[_vm._v("Submit")])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }