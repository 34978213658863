<template>
  <div>
    <b-card v-if=sumsub>
      <h3 class="light-blue">{{ $t("activate_account_by_verifying") }}</h3>
      <br />
      <h5>{{ $t("your_details") }}</h5>
      <br />
      <app-timeline>
        <!-- 12 INVOICES HAVE BEEN PAID -->
        <app-timeline-item variant="primary">
          <h5 class="col-md-12">{{ $t("personal_info") }}</h5>
          <div v-if="sumsubPersonalInfo"
            class=" d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0" >
            <h6 class="row col-md-8 light-blue">
              <b-col cols="12" xl="6" md="6" lg="6">
                <small>
                  <!-- <span
                    v-html="main_contact_gender == 0 ? $t('mr') : $t('mrs')"
                  ></span> -->
                  {{ sumsubPersonalInfo.firstName.value }} {{ sumsubPersonalInfo.name ? sumsubPersonalInfo.name.value : sumsubPersonalInfo.lastName.value}}</small>
                <br />
                <small>
                  {{ sumsubPersonalInfo.streetName.value }} {{ sumsubPersonalInfo.streetNumber.value }}
                </small>
                <br />
                <small>  {{ sumsubPersonalInfo.city.value }} {{ sumsubPersonalInfo.postalCode.value }}</small>
                <br />
                <br />
                <span class="text-gray"> {{ $t("occupation") }}:</span>
                <br />
                <small>{{sumsubPersonalInfo.jobTitleProfession ?  sumsubPersonalInfo.jobTitleProfession.value : sumsubPersonalInfo.jobTiltleProfession.value }} </small>
                <br />
                <br />
                <span class="text-gray"> {{ $t("industry") }}: </span> <br />
                <small> {{ sumsubPersonalInfo.businessActivity ? sumsubPersonalInfo.businessActivity.value : sumsubCompanyInfo.businessActivity.value}} </small>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <span class="text-gray"> {{ $t("born") }}: </span> <br />
                <small>
                  {{ sumsubPersonalInfo.dateOfBirth
                      ? sumsubPersonalInfo.dateOfBirth.value.replaceAll("-", ".")
                      : "" }}
                </small>
                <br />
                <br />
                <span class="text-gray"> {{ $t("nationality") }}: </span> <br />
                <small> {{ sumsubPersonalInfo.nationality.value }}</small>
                <br />
                <br />
                <span class="text-gray"> {{ $t("email") }}: </span>
                <br /><small>
                  {{ main_contact_email }}
                </small>
              </b-col>
            </h6>
          </div>
        </app-timeline-item>
        <app-timeline-item variant="primary">
          <div
            class="d-flex flex-sm-row flex-column flex-wrap justify-content-between  mb-1 mb-sm-0">
            <h6 class="row col-md-6">
              <b-col cols="12" v-if="sumsubFinancialInfo">
                <h5>{{ $t("financial_situation") }}</h5>
                <small>
                  {{ financial_questions[0].question }}:
                  <strong v-if="sumsubFinancialInfo.doYouOwnFixedAssetsO.value == 'yes'">{{ $t("yes") }}</strong>
                  <strong v-else>{{ $t("no") }}</strong>
                  <br />
                </small>
                <small>
                  {{ financial_questions[1].question }}:
                  <strong v-if="sumsubFinancialInfo.canYouCopeWithYourMo.value == 'yes'">{{ $t("yes") }}</strong>
                  <strong v-else>{{ $t("no") }}</strong>
                  <br />
                </small>
                <small>
                  {{ financial_questions[2].question }}:
                  <strong v-if="sumsubFinancialInfo.canYouCopeWithAnInve.value == 'yes'">{{ $t("yes") }}</strong>
                  <strong v-else>{{ $t("no") }}</strong>
                  <br />
                </small>
              </b-col>
            </h6>
          </div>
        </app-timeline-item>
        <app-timeline-item variant="primary">
          <div v-if="sumsubPoliticalInfo"
            class=" d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6 class="row col-md-6">
              <b-col cols="12">
                <h5>{{ $t("qualification_as_pep") }}</h5>
                <small>
                  {{ $t("political_q_1") }}:
                  <strong v-if="sumsubPoliticalInfo.iDeclareThatIAmAPoli.value == 'yes'">{{ $t("yes") }}</strong>
                  <strong v-else>{{ $t("no") }}</strong>
                  <br />
                </small>
                <small v-if="sumsubPoliticalInfo.iDeclareThatIAmAPoli.value == 'yes'">
                  {{ $t("country_name") }}:
                  <strong v-if="sumsubPoliticalInfo.iDeclareThatIAmAPoli.value == 'yes'">{{ sumsubPoliticalInfo.inTheLollowingCountr.value }}</strong>
                  <br />
                </small>
                <small v-if="sumsubPoliticalInfo.iDeclareThatIAmAPoli.value == 'yes'">
                  {{ $t("position") }}:
                  <strong v-if="sumsubPoliticalInfo.iDeclareThatIAmAPoli.value == 'yes'">{{ sumsubPoliticalInfo.functionRole.value}}</strong>
                  <br />
                </small>
                <small>
                  {{ $t("political_q_2") }}:
                  <strong v-if="sumsubPoliticalInfo.iDeclareThatIAmClose.value == 'yes'">{{ $t("yes") }}</strong>
                  <strong v-else>{{ $t("no") }}</strong>
                  <br />
                </small>
                <small v-if="sumsubPoliticalInfo.iDeclareThatIAmClose.value == 'yes'">
                  {{ $t("country_name") }}:
                  <strong v-if="sumsubPoliticalInfo.iDeclareThatIAmClose.value == 'yes'">{{ sumsubPoliticalInfo.inTheLollowing_e4xuz.value}}</strong>
                  <br />
                </small>
                <small v-if="sumsubPoliticalInfo.iDeclareThatIAmClose.value == 'yes'">
                  {{ $t("first_name") }}:
                  <strong v-if="sumsubPoliticalInfo.iDeclareThatIAmClose.value == 'yes'">
                    {{ sumsubPoliticalInfo.nameOlThePerson.value}}</strong>
                  <br />
                </small>
                <small v-if="sumsubPoliticalInfo.iDeclareThatIAmClose.value == 'yes'">
                 {{ $t("last_name") }}:
                  <strong v-if="sumsubPoliticalInfo.iDeclareThatIAmClose.value == 'yes'">
                    {{ sumsubPoliticalInfo.lastNameOfThatPerson.value}}</strong>
                  <br />
                </small>
                <small v-if="sumsubPoliticalInfo.iDeclareThatIAmClose.value == 'yes'">
                  {{ $t("position") }}:
                  <strong v-if="sumsubPoliticalInfo.iDeclareThatIAmClose.value == 'yes'">
                    {{ sumsubPoliticalInfo.functionRole_0avvh.value}} </strong>
                  <br />
                </small>
                
              </b-col>
            </h6>
          </div>
        </app-timeline-item>
        <app-timeline-item variant="primary">
          <div class="">
            <h5 class="col-md-12" v-if="sumsub.review.levelName == 'individual-investors' || (  sumsub.review.levelName == 'institutional-investor/buyer' && sumsubContact.areYouTheUltimateBen.value == 'yes')">
              {{ $t("i_am_beneficial_owner") }}
            </h5>
            <h5 class="col-md-12" v-else>
              {{ $t("following_person_is_beneficial_owner") }}
            </h5>
         
            <h6 class="row col-md-8 light-blue" v-if="sumsub.review && sumsub.review.levelName == 'individual-investors'">
              <b-col cols="12" xl="6" md="6" lg="6" v-if="sumsubPersonalInfo">
                <small>
                  <!-- <span
                    v-html="main_contact_gender == 0 ? $t('mr') : $t('mrs')"
                  ></span> -->
                  {{ sumsubPersonalInfo.firstName.value }} {{ sumsubPersonalInfo.name.value }}</small>
                <br />
                <small>
                  {{ sumsubPersonalInfo.streetName.value }} {{ sumsubPersonalInfo.streetNumber.value }}</small>
                <br />
                <small>
                  {{ sumsubPersonalInfo.city.value }} {{ sumsubPersonalInfo.postalCode.value }}
                  {{ sumsubPersonalInfo.country.value }}</small>
                <br />
                <small ><strong class="text-gray font-weight-bolder">{{ $t("born") }}:</strong>
                  {{ sumsubPersonalInfo.dateOfBirth
                      ? sumsubPersonalInfo.dateOfBirth.value.replaceAll("-", ".")
                      : ""}}
                </small>
                <br />
                <small
                  ><strong class="text-gray font-weight-bolder"
                    >{{ $t("nationality") }}:</strong
                  >   {{ sumsubPersonalInfo.nationality.value }}</small>
              </b-col>
            </h6>
            <h6
              class="row col-md-8"
              v-else-if="sumsub.review && sumsub.review.levelName == 'institutional-investor/buyer' && sumsubContact.areYouTheUltimateBen.value == 'yes'"
            >
              <b-col cols="12" xl="6" md="6" lg="6">
                <br />
                <small>
                  {{ sumsubContact.firstName.value }} {{ sumsubContact.lastName.value }}
                  <br />
                  {{ sumsubContact.streetName.value }} {{ sumsubContact.streetNumber.value }}
                  <br />
                  {{ sumsubContact.postalCode.value }} {{ sumsubContact.city.value }}, {{ sumsubContact.country.value }}</small>
                  <br />
                <small class="font-weight-bolder text-gray">
                  {{ $t("born") }}:
                </small>
                <small> {{ sumsubContact.dateOfBirth.value | formatDate }}</small>
                <br />
                <small class="font-weight-bolder text-gray">
                  {{ $t("nationality") }}:
                </small>
                <small> {{ sumsubContact.nationality.value }}</small>
                <br />
              </b-col>
            </h6>
            <h6
              class="row col-md-8"
              v-else-if="sumsub.review && sumsub.review.levelName == 'institutional-investor/buyer' && sumsubContact.areYouTheUltimateBen.value == 'no'"
            >
              <b-col cols="12" xl="6" md="6" lg="6">
                <br />
                <small>
                  {{ sumsubBeneficialOwner.firstName.value }} {{ sumsubBeneficialOwner.lastName.value }}
                  <br />
                  {{ sumsubBeneficialOwner.streetName.value }} {{ sumsubBeneficialOwner.streetNumber.value }}
                  <br />
                  {{ sumsubBeneficialOwner.postalCode.value }} {{ sumsubBeneficialOwner.city.value }}, {{ sumsubBeneficialOwner.country.value }}</small>
                  <br />
                <small class="font-weight-bolder text-gray">
                  {{ $t("born") }}:
                </small>
                <small v-if="sumsubBeneficialOwner.dateOfBirth"> {{ sumsubBeneficialOwner.dateOfBirth.value | formatDate }}</small>
                <br />
                <small class="font-weight-bolder text-gray">
                  {{ $t("nationality") }}:
                </small>
                <small> {{ sumsubBeneficialOwner.nationality.value }}</small>
                <br />
              </b-col>
            </h6>
          </div>
        </app-timeline-item>
        <app-timeline-item variant="primary">
          <div
            class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0" >
            <h6 class="col-md-6">
              <h5>{{ $t("mTan_confirmation_title") }}</h5>
              <small>{{ $t("mTan_confirmation_text_1_before_link") }} </small>
              <span class="web">
                <a
                  @click="toTerms()"
                  >{{ $t("mTan_confirmation_text_1_link") }}</a>
             
              </span>
              <span class="mobile">
                <a
                @click="toTerms()"
                  >{{ $t("mTan_confirmation_text_1_link") }}</a>
         
              </span>

              <small> {{ $t("mTan_confirmation_text_1_after_link") }}</small>
              <br />
              <small>{{ $t("mTan_confirmation_text_2") }}</small>
              <br />
            </h6>
          </div>
        </app-timeline-item>
        <app-timeline-item variant="primary">
          <div
            class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0" >
            <h6 class="col-md-5">
              <p>{{ $t("your_phone_number") }}</p>
              <b-input-group>
                <b-input-group-prepend is-text id="phoneCountries">
                  <b-form-select :options="countries" v-model.trim="phoneContries"> 
                  </b-form-select>
                </b-input-group-prepend>
                <b-form-input
                  id="main-contact-tel"
                  v-model.trim="main_contact_tel"
                  @input="checkPhoneFormat"
                  class="form-control"
                  :disabled="verified_at ? true : false"
                  required
                >
                </b-form-input>
              </b-input-group>
              <small class="text-danger is-invalid" v-if="phoneValidation">{{
                phoneValidation
              }}</small>
            </h6>
            <div class="col-md-12 mt-1" v-if="!verified_at">
              <b-button variant="primary" @click="sendMTan()">{{
                $t("request_mtan")
              }}</b-button>
            </div>
          </div>
        </app-timeline-item>
        <app-timeline-item variant="primary" v-if="mtanValue && verified_at">
          <div
            class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6 class="col-md-6 text-success">
              <feather-icon
                icon="CheckIcon"
                class="text-success"
                variant="success"
                size="21"
              />
              {{ $t("mtan_before_number_text") }}
              {{ mtanValue }}
              {{ $t("mtan_after_number_text") }}
              <br />
            </h6>
          </div>
        </app-timeline-item>
      </app-timeline>
      <br />
      <div class="col-md-12" v-if="mTanSent">
        <b-row class="jumbotron" id="mtan-div">
          <b-col class="col-md-12 mb-1 row">
            <h5>{{ $t("you_have_received_the_mTAN_on_your_mobile") }}</h5>
          </b-col>
          <b-col class="col-md-12 row">
            <div>
              <b-form-input
                v-model.trim="mTan"
                placeholder=""
                class="mr-1"
              ></b-form-input>
            </div>
            <div>
              <b-button variant="primary" class="ml-2" @click="verifyMTan()">{{
                $t("i_confirm")
              }}</b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- <div v-for="country in list">
        {{ country.name.common }}: + ({{ country.cca3 }}) {{ country.idd.root }}{{ country.idd.suffixes}}
      </div> -->
    </b-card>
    <b-card  v-if="showLoader">
      <b-col cols="12" xl="6" md="6" lg="6">
        <img src="/new-loader.svg" />
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { VueAutosuggest } from "vue-autosuggest";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Cleave from "vue-cleave-component";
import axios from 'axios';
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
} from "bootstrap-vue";
import { phone } from "phone";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormDatepicker,
    BFormRadio,
    BFormRadioGroup,
    BAlert,
    BInputGroupPrepend,
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
    AppTimeline,
    AppTimelineItem,
    Cleave,
  },
  name: "ActivateAccount",
  data() {
    return {
      //phoneContries: "CH",
      beneficial: 1,
      mTan: null,
      mTanSent: false,
      type: "private",
      telCode: "",
      countries: this.$store.state.app.countries,
      countries_code: this.$store.state.app.countries_code,
      showBlueBorder: false,
      nationalities: ["Switzerland", "Albanian", "Macedonian", "German"],
      positions: ["Employed", "Self Employed", "Retired", "Etc"],
      main_contact_gender: "",
      main_contact_email: this.$store.state.auth.user.email,
      main_contact_first_name: this.$store.state.auth.user.first_name,
      main_contact_last_name: this.$store.state.auth.user.last_name,
      main_contact_birthday: this.$store.state.auth.user.birthday,
      main_contact_nationality: this.$store.state.auth.user.nationality,
      main_contact_zip: this.$store.state.auth.user.zip,
      main_contact_address: this.$store.state.auth.user.address,
      main_contact_address_nr: this.$store.state.auth.user.address_nr,
      main_contact_city: this.$store.state.auth.user.city,
      main_contact_country_id: this.$store.state.auth.user.country_id,
      main_contact_tel: this.$store.state.auth.user.tel,
      phoneContries: this.$store.state.auth.user.iso_code,
      main_contact_position: this.$store.state.auth.user.position,
      main_contact_industry: this.$store.state.auth.user.industry,
      main_contact_country: null,
      verified_at: null,
      financial_questions: [
        { id: 1, question: this.$t("question_1"), answer: false },
        { id: 2, question: this.$t("question_2"), answer: false },
        { id: 3, question: this.$t("question_3"), answer: false },
      ],
      qualification_questions: [
        { id: 4, question: this.$t("question_4"), answer: false },
        { id: 5, question: this.$t("question_5"), answer: false },
        { id: 6, question: this.$t("question_6"), answer: false },
        { id: 7, question: this.$t("question_7"), answer: false },
      ],
      terms_and_conditions: false,
      disableInputs: false,
      mtanValue: "",
      phoneValidation: "",
      owners: [],
      phoneOptions: {
        phone: {
          delimiters: [" ", " ", " "],
          blocks: [2, 3, 2, 2],
        },
      },
      sumsub: null,
      sumsubPersonalInfo: null,
      sumsubFinancialInfo: null,
      sumsubPoliticalInfo: null,
      sumsubContact: null,
      sumsubBeneficialOwner: null,
      showLoader: false,
      sumsubCompanyInfo: null,
      list: [],
    };
  },
  
  mounted() {

  },
  created() {
    this.getUser();
    this.getSumsubInfo();
  },
  methods: {
  
    toTerms(){
      if(this.$i18n.locale == "en"){
        window.open("https://www.aequitex.com/terms-condition", "_blank");
      }
      else{
        window.open("https://www.aequitex.com/de/bedingungen-und-konditionen", "_blank");
      }
      },
    getSumsubInfo(){
      this.showLoader = true;
      this.$http.post(`/buyer/getSumsubClientId/status`)
      .catch((err) => {
            if(err){
                this.showLoader = false
            }
        })
        .then((res) => {
            if(res){
                this.showLoader = false;
                this.sumsub = res.data.value.status;
                if(res.data.value.status && res.data.value.status.review && res.data.value.status.review.levelName == 'individual-investors'){
                   const sections = res.data.value.status.questionnaires[0].sections ? res.data.value.status.questionnaires[0].sections : [];
                   this.sumsubPersonalInfo = sections.personalInformation.items;
                   this.sumsubFinancialInfo = sections.financialSituation.items;
                   this.sumsubPoliticalInfo = sections.politicallyExposedPe.items;
                }
                else if(res.data.value.status && res.data.value.status.review && res.data.value.status.review.levelName == 'institutional-investor/buyer'){
                    const sections = res.data.value.status.questionnaires[1].sections ? res.data.value.status.questionnaires[1].sections : [];
                    this.sumsubPersonalInfo = sections.contactPerson.items;
                    this.sumsubCompanyInfo = sections.companyInformation.items;
                    this.sumsubFinancialInfo = sections.financialSituation.items;
                    this.sumsubPoliticalInfo = sections.politicallyExposedPe.items;
                    this.sumsubContact = sections.contactPerson.items;
                    this.sumsubBeneficialOwner = sections.uboUltimateBeneficia.items;
                }
               
              
            }
        });
    },
    checkPhoneFormat() {
      let validation = phone(this.main_contact_tel, {
        country: this.phoneContries,
        validateMobilePrefix: false
      });

      if (validation && validation.isValid == false) {
        this.phoneValidation = `Phone for ${this.phoneContries} must be valid`;
      } else {
        this.phoneValidation = "";
      }
    },
    sendMTan() {
      let data = {
        tel: this.getPhone(this.phoneContries, this.main_contact_tel),
            country: this.phoneContries,
      }
      console.log('dataa',data)
      if (this.phoneValidation.length < 1) {
        this.$http
          .post("/buyer/sentMTan", {
            tel: this.getPhone(this.phoneContries, this.main_contact_tel),
            country: this.phoneContries,
          })
          .then((res) => {
            let mess = "";
            let icon = "";
            let variant = "";
            if (res && res.data.status == 500) {
              mess = res.data.message;
              icon = "ErrorIcon";
              variant = "danger";
            } else {
              this.mTanSent = true;
              this.disableInputs = true;
              mess = "success";
              icon = "SuccessIcon";
              variant = "success";

              setTimeout(function () {
                const mtan = document.getElementById("mtan-div");
                if (mtan) {
                  mtan.scrollIntoView({ behavior: "smooth", block: "center" });
                }
              }, 500);
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t(mess),
                icon: icon,
                variant: variant,
              },
            });
          });
      }
    },
    verifyMTan() {
      if (this.mTan) {
        this.$http
          .post("/buyer/verifyMTan", { code: this.mTan })
          .then((res) => {
            if (res.data.status === 200) {
              this.mTanSent = false;
              this.disableInputs = true;
              window.location = '/';
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t(res.data.message),
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
              this.disableInputs = false;
            }
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("code_is_required"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    getUser() {
      this.$http.post("/buyer/show", {}).then((res) => {
        if(res.data.value.status == 1){
          // this.$router.push('/')
        }
        this.beneficial = res.data.value.beneficial;
        this.owners = res.data.value.owners;
        this.$store.state.app.countries_code.forEach((el) => {
          this.owners.forEach((owner) => {
            if (el.id.toString() == owner.nationality) {
              owner.nationality_id = el.id;
              owner.nationality =
                this.$store.state.app.language == "de" ? el.name_de : el.name;
            }
          });
        });
        this.main_contact_gender = res.data.value.gender;
        this.main_contact_first_name = res.data.value.name;
        this.main_contact_last_name = res.data.value.last_name;
        this.main_contact_position =
          this.positions[res.data.value.position - 1];
        this.phoneContries = res.data.value.iso_code;
        this.verified_at = res.data.value.verified_at;
        this.main_contact_address = res.data.value.address;
        this.main_contact_address_nr = res.data.value.address_nr;
        this.main_contact_birthday = res.data.value.birthday;
        this.main_contact_nationality = res.data.value.nationality;
        this.main_contact_industry = res.data.value.industry;
        this.main_contact_zip = res.data.value.zip;
        this.main_contact_city = res.data.value.city;
        this.main_contact_email = res.data.value.email;
        this.main_contact_country = res.data.value.country.name;
        this.mtanValue = res.data.value.mTan;
        this.main_contact_tel = this.getPhone(
          res.data.value.iso_code,
          res.data.value.tel
        );
      });
    },
    getPhone(iso, tel) {
      if (this.countries_code) {
        this.countries_code.map((i, item) => {
          if (i.iso_code == iso) {
            tel = tel.replace(i.phone_code, "");
          }
        });
      }
      return tel;
    },
    notActiveNow() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("not_yet_active_for_companies"),
          icon: "ErrorIcon",
          variant: "error",
        },
      });
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      this.country_id = null;
    },
  },
};
</script>

<style scoped>
#phoneCountries select {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-group-text {
  padding: 0;
  border: 0 !important;
}
.text-gray {
  color: #5e5873;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
