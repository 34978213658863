<template>
  <div>
    <div>
      <h3>{{ $t("add_buyer_details") }}</h3>
      <p>
        {{ $t("we_need_some_basic_data_from_you") }}
      </p>
      <validation-observer ref="buyerRegisterValidation" name="RegisterBuyer">
        <b-row>
          <b-col cols="12">
            <b-button :variant="!displayCompany ? 'primary' : 'outline-primary'" class="mb-1"
              @click="displayCompany = false">
              <feather-icon icon="user"></feather-icon>
              {{ $t("i_am_investing_as_private_person") }}
            </b-button>
            <b-button :variant="displayCompany ? 'primary' : 'outline-primary'" @click="displayCompany = true"
              class="ml-xl-1 ml-lg-1 ml-md-1 ml-sx-0 mb-1">
              <feather-icon icon="briefcase"></feather-icon>
              {{ $t("i_am_investing_in_the_name_of_a_company") }}
            </b-button>
          </b-col>
        </b-row>

        <b-form @submit="onSubmit" class="mt-1" @reset="onReset">
          <h5>{{ $t("your_details") }}</h5>
          <b-card>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6" v-if="displayCompany">
                <b-form-group :label="$t('company_name') + ':'">
                  <validation-provider #default="{ errors }" name="Company name" rules="required">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text :class="main_contact_company_name != null
                          ? ''
                          : showBlueBorder
                            ? 'is-empty'
                            : ''
                        ">
                        <feather-icon icon="CodesandboxIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input v-model.trim="main_contact_company_name" required :class="main_contact_company_name != null
                          ? ''
                          : showBlueBorder
                            ? 'is-empty'
                            : ''
                        " :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="main-contact-gender-group" :label="$t('salutation') + ':'"
                  label-for="main-contact-gender">
                  <validation-provider #default="{ errors }" name="main-contact-gender" rules="required">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text :class="main_contact_gender != null
                          ? ''
                          : showBlueBorder
                            ? 'is-empty'
                            : ''
                        ">
                        <feather-icon icon="UserIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-select id="main-contact-gender" :class="main_contact_gender != null
                          ? ''
                          : showBlueBorder
                            ? 'is-empty'
                            : ''
                        " v-model.trim="main_contact_gender" 
                          :options="[
                             {
                               text: $t('select_one'),
                               value: null,
                               disabled: true,
                             },
                             { text: $t('mr'), value: '0' },
                             { text: $t('mrs'), value: '1' },
                           ]" required :state="errors.length > 0 ? false : null"></b-form-select>
                    </b-input-group>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="main-contact-first-name-group" :label="$t('first_name') + ':'"
                  label-for="main-contact-first-name">
                  <validation-provider #default="{ errors }" name="First name" rules="required">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text :class="main_contact_first_name != null
                          ? ''
                          : showBlueBorder
                            ? 'is-empty'
                            : ''
                        ">
                        <feather-icon icon="UserIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input id="main-contact-first-name" v-model.trim="main_contact_first_name" required :class="main_contact_first_name != null
                          ? ''
                          : showBlueBorder
                            ? 'is-empty'
                            : ''
                        " :state="errors.length > 0 ? false : null" :disabled="disableInputs"></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="main-contact-birthday-group" :label="$t('birthday') + ':'" label-for="main-contact-tel">
                  <validation-provider #default="{ errors }" name="Birthday" rules="required">
                    <b-form-datepicker no-flip id="birhtday" :max="ageRestriction()" v-model.trim="main_contact_birthday"
                      :class="main_contact_birthday != null
                          ? ''
                          : showBlueBorder
                            ? 'is-empty'
                            : ''
                        " :state="errors.length > 0 ? false : null" :disabled="disableInputs"
                      required></b-form-datepicker>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="main-contact-last-name-group" :label="$t('last_name') + ':'"
                  label-for="main-contact-last-name">
                  <validation-provider #default="{ errors }" name="Last Name" rules="required">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text :class="main_contact_last_name != null
                          ? ''
                          : showBlueBorder
                            ? 'is-empty'
                            : ''
                        ">
                        <feather-icon icon="UserIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input id="main-contact-last-name" v-model.trim="main_contact_last_name" :class="main_contact_last_name != null
                          ? ''
                          : showBlueBorder
                            ? 'is-empty'
                            : ''
                        " required :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="main-contact-nationality-group" :label="$t('nationality') + ':'"
                  label-for="main-contact-nationality">
                  <validation-provider #default="{ errors }" name="Nationality" rules="required">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text :class="main_contact_nationality != null
                          ? ''
                          : showBlueBorder
                            ? 'is-empty'
                            : ''
                        ">
                        <feather-icon icon="UserPlusIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-select id="nationality" v-model.trim="main_contact_nationality" :class="main_contact_nationality != null
                          ? ''
                          : showBlueBorder
                            ? 'is-empty'
                            : ''
                        " :options="$store.state.app.language == 'en'
      ? countries
      : countries_de
    " required :state="errors.length > 0 ? false : null"></b-form-select>
                    </b-input-group>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <h5>
            {{ $t("contact") }}
          </h5>
          <b-card>
            <b-row m-0 p-0>
              <b-col cols="12" xl="4" md="4" lg="4">
                <b-form-group id="main-contact-address-group" :label="$t('address') + ':'"
                  label-for="main-contact-address">
                  <validation-provider #default="{ errors }" name="main-contact-address" rules="required">
                    <b-form-input id="main-contact-address" v-model.trim="main_contact_address" required
                      :state="errors.length > 0 ? false : null"></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="2" md="2" lg="2">
                <b-form-group id="main-contact-address-nr-group" :label="$t('address_nr') + ':'"
                  label-for="main-contact-address-nr">
                  <validation-provider #default="{ errors }" name="main-contact-address-nr" rules="required">
                    <b-form-input id="main-contact-address-nr" v-model.trim="main_contact_address_nr" required
                      :state="errors.length > 0 ? false : null"></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="main-contact-email-group" :label="$t('company_email_address') + ':'"
                  label-for="main-contact-email">
                  <validation-provider #default="{ errors }" name="Contact Email" rules="required|email">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text :class="main_contact_email != null
                          ? ''
                          : showBlueBorder
                            ? 'is-empty'
                            : ''
                        ">
                        <feather-icon icon="MailIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input id="main-contact-email" v-model.trim="main_contact_email" :class="main_contact_email != null
                          ? ''
                          : showBlueBorder
                            ? 'is-empty'
                            : ''
                        " required disabled :state="errors.length > 0 ? false : null"></b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="2" md="2" lg="2">
                <b-form-group id="zip-group" :label="$t('zip') + ':'" label-for="zip">
                  <validation-provider #default="{ errors }" name="zip" rules="required">
                    <b-form-input id="zip" v-model.trim="main_contact_zip" required
                      :state="errors.length > 0 ? false : null"></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="4" md="4" lg="4">
                <b-form-group id="city-group" :label="$t('city') + ':'" label-for="city">
                  <validation-provider #default="{ errors }" name="city" rules="required">
                    <b-form-input id="city" v-model.trim="main_contact_city" required
                      :state="errors.length > 0 ? false : null"></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="tel-group" :label="$t('tel') + ':'" label-for="tel">
                  <validation-provider #default="{ errors }" name="tel" rules="required">
                    <b-input-group>
                      <b-input-group-prepend id="phoneCountries">
                        <b-form-select :options="nationalities" v-model.trim="phoneContries" class="form-control"
                          :state="errors.length > 0 ? false : null">
                        </b-form-select>
                      </b-input-group-prepend>
                      <b-form-input id="main-contact-tel" v-model.trim="main_contact_tel" @input="checkPhoneFormat"
                        class="form-control" :state="errors.length > 0 ? false : null">
                      </b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                    <small class="text-danger is-invalid" v-if="phoneValidation">{{ phoneValidation }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="main-contact-country-group" :label="$t('country_name') + ':'"
                  label-for="main-contact-country-id">
                  <validation-provider #default="{ errors }" name="Country" rules="required">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text :class="main_contact_country_id != null
                          ? ''
                          : showBlueBorder
                            ? 'is-empty'
                            : ''
                        ">
                        <feather-icon icon="UserPlusIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-select id="signing-authority-type" v-model.trim="main_contact_country_id" :class="main_contact_country_id != null
                          ? ''
                          : showBlueBorder
                            ? 'is-empty'
                            : ''
                        " :options="$store.state.app.language == 'en'
                         ? countries
                         : countries_de
                          " required :state="errors.length > 0 ? false : null"></b-form-select>
                    </b-input-group>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <h5>
            {{ $t("your_details") }}
          </h5>
          <b-card>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="main-contact-position-group" :label="$t('position') + ':'"
                  label-for="main-contact-position">
                  <validation-provider #default="{ errors }" name="Position" rules="required">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserPlusIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-select id="position" v-model.trim="main_contact_position" :options="[
                        {
                          text: $t('select_one'),
                          value: null,
                          disabled: true,
                        },
                        { text: $t('employed'), value: '1' },
                        { text: $t('self-employed'), value: '2' },
                        { text: $t('retired'), value: '3' },
                        { text: $t('etc'), value: '0' },
                      ]" required :state="errors.length > 0 ? false : null"></b-form-select>
                    </b-input-group>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group id="industry-group" :label="$t('industry') + ':'" label-for="industry">
                  <validation-provider #default="{ errors }" name="industry" rules="required">
                    <b-form-input id="industry" v-model.trim="main_contact_industry" required
                      :state="errors.length > 0 ? false : null"></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <h5>{{ $t("financial_situation") }}</h5>
          <b-card style="padding: 10px">
            <b-row m-0 p-0>
              <b-col cols="8" m-0 p-0>
                <!-- terms-and-conditions -->
                <b-form-group>
                  <div class="p-0">
                    <h6>
                      {{ $t("financial_buyer_text_1") }}
                    </h6>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="light-blue">
              {{ $t("please_answer_this_questions") }}
              <feather-icon icon="ArrowDownCircleIcon" size="18" />
            </div>
            <br />
            <b-row v-for="(item, index) in financial_questions" :key="index">
              <b-col cols="12">
                <span>{{ $t(item.question) }}
                  <feather-icon icon="HelpCircleIcon" role="button" v-b-tooltip.hover
                    :title="$t('tooltip_financial_' + index)" size="18" /></span>
                <div class="mt-1">
                  <b-button :variant="item.answer == 1 ? 'primary' : 'outline-primary'" class="width-100"
                    @click="item.answer = 1">{{ $t("yes") }}</b-button>
                  <b-button :variant="item.answer == 0 ? 'primary' : 'outline-primary'" class="width-100 ml-1"
                    @click="item.answer = 0">{{ $t("no") }}</b-button>
                </div>
                <hr />
              </b-col>
            </b-row>
            <div v-if="missingFinancialQuestions" class="mt-2 text-danger "> Please answer all questions!</div>
          </b-card>
          <h5>{{ $t("qualification_as_pep") }}</h5>
          <b-card style="padding: 10px">
            <b-row m-0 p-0>
              <b-col cols="12" m-0 p-0>
                <!-- terms-and-conditions -->
                <b-form-group>
                  <div class="p-0">
                    <h6>
                      {{ $t("qualification_buyer_text_1") }}
                    </h6>
                    <div class="light-blue">
                      {{ $t("please_answer_this_questions") }}
                      <feather-icon icon="ArrowDownCircleIcon" size="18" />
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-for="(item, index) in qualification_questions" :key="index">
              <b-col cols="12">
                <span>{{ $t(item.question) }}
                  <feather-icon icon="HelpCircleIcon" size="18" role="button" v-b-tooltip.hover
                    :title="$t('tooltip_pep_' + index)" /></span>
                <div class="mt-1">
                  <b-button :variant="item.answer == 1 ? 'primary' : 'outline-primary'" class="width-100"
                    @click="item.answer = 1">{{ $t("yes") }}</b-button>
                  <b-button :variant="item.answer == 0 ? 'primary' : 'outline-primary'" class="width-100 ml-1"
                    @click="item.answer = 0">{{ $t("no") }}</b-button>
                </div>
                <hr />
              </b-col>
            </b-row>
            <div v-if="missingQualificationQuestions" class="mt-2 text-danger "> Please answer all questions!</div>
          </b-card>
          <h5>{{ $t("legal_notice") }}</h5>
          <b-card>
            <b-row m-0 p-0>
              <b-col cols="12">
                <!-- terms-and-conditions -->
                <b-form-group>
                  <validation-provider #default="{ errors }" name="terms-and-conditions" rules="required">
                    <b-form-checkbox id="terms-and-conditions" v-model.trim="terms_and_conditions"
                      name="terms-and-conditions" :state="errors.length > 0 ? false : null" :disabled="disableInputs">
                      {{ $t("agree_with_terms_buyer_before_link") }}
                      <span class="web">
                        <a @click="toTerms()">{{
                          $t("agree_with_terms_buyer_link")
                        }}</a>

                      </span>
                      <span class="mobile">
                        <a @click="toTerms()">{{
                          $t("agree_with_terms_buyer_link")
                        }}</a>
                      </span>
                    </b-form-checkbox>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>

                  <div style="background: #efefef; padding: 10px" class="mt-1">
                    <p>
                      {{ $t("agree_with_terms_buyer_0") }}
                    </p>
                    <div class="col-md-12">
                      <p>
                        {{ $t("agree_with_terms_buyer_1") }}
                      </p>
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <b-row m-0 p-0 v-if="$store.state.app.firstTask">
            <b-col cols="12" class="text-right">
              <b-button variant="primary" @click="saveBuyerInfo()">
                <feather-icon icon="SaveIcon"></feather-icon>
                {{ $t("submit") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>
  
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { VueAutosuggest } from "vue-autosuggest";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { required, email } from "@validations";
import Cleave from "vue-cleave-component";
import moment from "moment";
import { phone } from "phone";
import i18n from "@/libs/i18n";
import PDFViewer from "pdf-viewer-vue/dist/vue2-pdf-viewer";
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
} from "bootstrap-vue";
export default {
  components: {
    PDFViewer,
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormDatepicker,
    BFormRadio,
    BFormRadioGroup,
    BAlert,
    BInputGroupPrepend,
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
    Cleave,
  },
  name: "AddBuyer",
  data() {
    return {
      displayCompany: false,
      type: "private",
      countries: [],
      countries_de: [],
      countries_code: [],
      showBlueBorder: false,
      nationalities: [],
      positions: [
        { text: i18n.t("employed"), value: i18n.t("employed") },
        { text: i18n.t("self-employed"), value: i18n.t("self-employed") },
        { text: i18n.t("retired"), value: i18n.t("retired") },
        { text: i18n.t("etc"), value: i18n.t("etc") },
      ],
      main_contact_gender: null,
      main_contact_email: this.$store.state.auth.user.email,
      main_contact_first_name: this.$store.state.auth.user.first_name,
      main_contact_last_name: this.$store.state.auth.user.last_name,
      main_contact_company_name: this.$store.state.auth.user.company_name,
      main_contact_birthday: "",
      main_contact_nationality: this.$store.state.auth.user.nationality_id,
      main_contact_zip: this.$store.state.auth.user.zip,
      main_contact_address: this.$store.state.auth.user.address,
      main_contact_address_nr: this.$store.state.auth.user.address_nr,
      main_contact_city: this.$store.state.auth.user.city,
      main_contact_country_id: this.$store.state.auth.user.country_id,
      main_contact_tel: this.getPhone(
        this.$store.state.auth.user.iso_code,
        this.$store.state.auth.user.tel
      ),
      main_contact_position: null,
      main_contact_industry: this.$store.state.auth.user.industry,
      confirmation_checkbox: true,
      financial_questions: [
        {
          id: 1,
          question: "question_1",
          answer: false,
          description: this.$t("question_1_description"),
        },
        {
          id: 2,
          question: "question_2",
          answer: false,
          description: this.$t("question_2_description"),
        },
        {
          id: 3,
          question: "question_3",
          answer: false,
          description: this.$t("question_3_description"),
        },
      ],
      qualification_questions: [
        {
          id: 4,
          question: "question_4",
          answer: false,
          description: this.$t("question_4_description"),
        },
        {
          id: 5,
          question: "question_5",
          answer: false,
          description: this.$t("question_5_description"),
        },
        {
          id: 6,
          question: "question_6",
          answer: false,
          description: this.$t("question_6_description"),
        },
        {
          id: 7,
          question: "question_7",
          answer: false,
          description: this.$t("question_7_description"),
        },
      ],
      terms_and_conditions: false,
      disableInputs: false,
      phoneOptions: {
        phone: {
          delimiters: [" ", " ", " "],
          blocks: [2, 3, 2, 2],
        },
      },
      phoneContries: "CH",
      phoneValidation: "",
      missingFinancialQuestions: false,
      missingQualificationQuestions: false,
    };
  },
  computed: {
    ageRestriction1() {
      if (this.main_contact_birthday) {
        let age = moment().diff(this.main_contact_birthday, "18");
        return age;
      } else {
        return "";
      }
    },
  },
  created() {
    this.$http.post("/countries").then((res) => {
      res.data.value.map((item) => {
        this.countries_code.push(item);
        this.countries.push({
          value: item.id,
          text: item.name,
        });
        this.countries_de.push({
          value: item.id,
          text: item.name_de,
        });
        this.nationalities.push({
          value: item.iso_code,
          text: `${item.iso_code} (${item.phone_code})`,
        });
      });
    });
    this.getUser();
  },
  methods: {
    toTerms() {
      if (this.$i18n.locale == "en") {
        window.open("https://www.aequitex.com/terms-condition", "_blank");
      }
      else {
        window.open("https://www.aequitex.com/de/bedingungen-und-konditionen", "_blank");
      }
    },
    ageRestriction() {
      var oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 18);
      return oneYearFromNow;
    },
    checkPhoneFormat() {
      let validation = phone(this.main_contact_tel, {
        country: this.phoneContries,
        validateMobilePrefix: false
      });

      if (validation && validation.isValid == false) {
        this.phoneValidation = `Phone for ${this.phoneContries} must be valid`;
      } else {
        this.phoneValidation = "";
      }
    },
    getUser() {
      this.$http.post("/buyer/show", {}).then((res) => {
        if (res.data.value) {
          this.displayCompany = res.data.value.company_id != null ? true : false;
          this.main_contact_gender = res.data.value.gender
            ? res.data.value.gender
            : null;
          this.main_contact_first_name = res.data.value.first_name;
          this.main_contact_last_name = res.data.value.last_name;
          this.main_contact_company_name = res.data.value.company_name;

          this.main_contact_position = res.data.value.position;

          this.main_contact_tel = this.getPhone(
            res.data.value.iso_code,
            res.data.value.tel
          );
          this.main_contact_address = res.data.value.address;
          this.main_contact_address_nr = res.data.value.address_nr;
          this.main_contact_country_id = res.data.value.country_id;

          this.main_contact_birthday = moment(
            res.data.value.birthday,
            "DD.MM.YYYY"
          ).format("YYYY-MM-DD");

          this.main_contact_nationality = res.data.value.nationality_id;
          this.main_contact_industry = res.data.value.industry;
          this.main_contact_zip = res.data.value.zip;
          this.main_contact_city = res.data.value.city;
          if (res.data.value.address) {
            this.terms_and_conditions = true;
          }
          this.phoneContries = res.data.value.iso_code
            ? res.data.value.iso_code
            : "CH";

          this.financial_questions.map((item) => {
            if (res.data.value.answers) {
              item.answer =
                res.data.value.answers["financial_answer_" + item.id];
            } else {
              item.answer = null;
            }
          });
          this.qualification_questions.map((item) => {
            if (res.data.value.answers) {
              item.answer =
                res.data.value.answers["qualification_answer_" + item.id];
            } else {
              item.answer = null;
            }
          });
        }
      });
    },
    getPhone(iso, tel) {
      if (this.countries_code && this.countries_code.length > 0) {
        this.countries_code.map((i, item) => {
          if (i.iso_code == iso) {
            tel = tel.replace(i.phone_code, "");
          }
        });
      }
      return tel;
    },
    saveBuyerInfo() {
      this.$refs.buyerRegisterValidation.validate().then((success) => {
        if (this.terms_and_conditions != true) {
          this.terms_and_conditions = '';
          this.$refs.buyerRegisterValidation.validate();
          return '';
        }
        if (success && this.phoneValidation.length < 1) {
          this.disableInputs = true;
          const formSubmissionData = {
            gender: this.main_contact_gender,
            first_name: this.main_contact_first_name,
            last_name: this.main_contact_last_name,
            company_name: this.main_contact_company_name,
            position: this.main_contact_position,
            tel: this.main_contact_tel,
            address: this.main_contact_address,
            address_nr: this.main_contact_address_nr,
            birthday: this.main_contact_birthday,
            nationality: this.main_contact_nationality,
            country_id: this.main_contact_country_id,
            industry: this.main_contact_industry,
            zip: this.main_contact_zip,
            city: this.main_contact_city,
            iso_code: this.phoneContries,
          };
          let data = {};
          this.financial_questions.map((item) => {
            const financial_question = {
              ["financial_answer_" + item.id]: item.answer,
            };
            data = { ...data, ...financial_question };
          });
          this.qualification_questions.map((item) => {
            const qualification_question = {
              ["qualification_answer_" + item.id]: item.answer,
            };
            data = { ...data, ...qualification_question };
          });
          data = { ...data, ...formSubmissionData };
          console.log(data)
          for (const item of this.financial_questions) {
            if (item.answer === null) {
              this.missingFinancialQuestions = true;
            }
            else {
              this.missingFinancialQuestions = false
            }
          }
          for (const item of this.qualification_questions) {
            if (item.answer === null) {
              this.missingQualificationQuestions = true;
            }
            else {
              this.missingQualificationQuestions = false
            }
          }

          if (!this.missingFinancialQuestions && !this.missingQualificationQuestions) {
            let mess = "";
            this.$http.post("/buyer/store", data).then((res) => {
              let icon = "";
              let variant = "";

              if (res && res.data.status == 500) {
                mess = res.data.message;
                icon = "ErrorIcon";
                variant = "error";
              } else {
                mess = "buyer_registered_successfully";
                icon = "SuccessIcon";
                variant = "success";
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t(mess),
                  icon: icon,
                  variant: variant,
                },
              });
              this.$router.push("/");
            });
          }
        } else {
          const el = document.getElementsByClassName("is-invalid");
          if (el) {
            setTimeout(function () {
              el[0].scrollIntoView({ behavior: "smooth", block: "center" });
            }, 500);
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Some fields are missing",
              icon: "ErrorIcon",
              variant: "danger",
            },
          });
          if (res.data.errors && res.data.errors.length > 0) {
            this.errors = res.data.errors;
            if (this.errors.length > 0) {
              const err = document.getElementsByClassName("is-invalid")[0];
              if (err) {
                setTimeout(function () {
                  err.scrollIntoView({ behavior: "smooth", block: "center" });
                }, 500);
              }
            }
          } else {
            this.$router.push("/");
          }
        }
        this.disableInputs = false;
      });
    },
    notActiveNow() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("not_yet_active_for_companies"),
          icon: "ErrorIcon",
          variant: "error",
        },
      });
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      this.country_id = null;
    },
  },
};
</script>
<style scoped></style>
  