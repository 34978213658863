<template>
  <b-card>
    <h3 class="light-blue">{{ $t("upload_identification_documents") }}</h3>
    <span class="primary">{{
      $t("upload_identification_documents_subtitle")
    }}</span>
    <br>
    <br>
    <b-card-text class="mt-5" v-if="!showLoader">
      <h6 class="mandatory-documents-title">{{ $t("mandatory_documents") }}</h6>
      <div>
        <div class="mandatory-documents-passport-front mt-1">
          <b-row>
            <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <h6 class="mandatory-documents-label">
                <span>{{ $t("passport_id_front") }}</span>
              </h6>
            </div>
            <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-xs-12"> 
              <div class="mb-1 font-weight-bolder">
                {{ $t("passport_front_text") }}
              </div>
              <div class="mb-1" v-if="passportFrontFile != null">
                {{ passportFrontFile }}
                <feather-icon
                  v-if="!hideButton"
                  @click="removeFile('passport_front', passportFrontFile)"
                  class="pointer text-danger"
                  icon="Trash2Icon"
                  size="18"
                />
              </div>

              <div v-if="passportFrontFile == null">
                <b-form-file
                  style="display: none"
                  @change="uploadFile($event, passport_front, 'passport_front')"
                  v-model.trim="passport_front"
                  :placeholder="$t('upload_a_file_or_drag_files_here')"
                  drop-placeholder="Drop file here..."
                  ref="passport_front"
                ></b-form-file>

                <b-row class="col-lg-6 col-md-12 mr-0 pr-0">
                  <b-alert show variant="info" class="col-md-12">
                    <b-row class="col-md-12"> 
                      <b-col
                        class="col-md-12 mt-2 mb-2 text-center pointer"
                        @click="triggerUploadFile('passport_front')"
                      >
                        <div>
                          <feather-icon icon="UploadIcon" size="25" />
                          <span></span>
                          {{ $t("upload_a_file_or_drag_files_here") }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-alert>
                </b-row>
              </div>
              <div v-else>
                <span class="uploaded-text"
                  >{{ $t("uploaded") }}
                  <feather-icon icon="CheckIcon"></feather-icon>
                </span>
              </div>
            </div>
          </b-row>
        </div>
        <div class="mandatory-documents-passport-back">
          <b-row>
            <div class="col-xl-3 col-lg-4 col-md-12">
              <h6 class="mandatory-documents-label">
                <span>{{ $t("passport_id_back") }}</span>
              </h6>
            </div>

            <div class="col-xl-9 col-lg-8 col-md-12">
              <div class="mb-1 font-weight-bolder">
                {{ $t("passport_back_text") }}
              </div>
              <div class="mb-1" v-if="passportBackFile != null">
                {{ passportBackFile }}
                <feather-icon
                  @click="removeFile('passport_back', passportBackFile)"
                  class="pointer text-danger"
                  v-if="!hideButton"
                  icon="Trash2Icon"
                  size="18"
                />
              </div>
              <div v-if="passportBackFile == null">
                <b-form-file
                  style="display: none"
                  @change="uploadFile($event, passport_back, 'passport_back')"
                  v-model.trim="passport_back"
                  :placeholder="$t('upload_a_file_or_drag_files_here')"
                  drop-placeholder="Drop file here..."
                  ref="passport_back"
                ></b-form-file>

                <b-row class="col-md-6 mr-0 pr-0">
                  <b-alert show variant="info" class="col-md-12">
                    <b-row class="col-md-12">
                      <b-col
                        class="col-md-12 mt-2 mb-2 text-center pointer"
                        @click="triggerUploadFile('passport_back')"
                      >
                        <div>
                          <feather-icon icon="UploadIcon" size="25" />
                          <span></span>
                          {{ $t("upload_a_file_or_drag_files_here") }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-alert>
                </b-row>
                <br />
                <b-form-group id="confirmation_checkbox">
                  <validation-provider
                    #default="{ errors }"
                    name="passport_back_included_in_passport_front_file"
                  >
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model.trim="file_passport_back_included"
                      name="passport_back_included_in_passport_front_file"
                      value="true"
                      unchecked-value="false"
                      @change="alreadyIncludedInFrontFile"
                    >
                      {{ $t("already_included_in_front_file") }}
                    </b-form-checkbox>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div v-else>
                <span class="uploaded-text"
                  >{{ $t("uploaded") }}
                  <feather-icon icon="CheckIcon"></feather-icon>
                </span>
              </div>
            </div>
          </b-row>
        </div>
       
        <div class="mandatory-documents-passport-utility-bill">
          <b-row>
            <div class="col-lg-3 col-md-12">
              <h6 class="mandatory-documents-label">
                <span>{{ $t("utility_bill") }}</span>
              </h6>
            </div>
            <div class="col-lg-9 col-md-12">
              <div class="mb-1">
                <span class="font-weight-bolder">{{
                  $t("utility_bill_text")
                }}</span>
              </div>
              <div class="mb-1" v-if="utilityBillFile != null">
                {{ utilityBillFile }}
                <feather-icon
                  @click="removeFile('utility_bill', utilityBillFile)"
                  class="pointer text-danger"
                  icon="Trash2Icon"
                  v-if="!hideButton"
                  size="18"
                />
              </div>
              <div v-if="utilityBillFile == null">
                <b-form-file
                  style="display: none"
                  @change="uploadFile($event, utility_bill, 'utility_bill')"
                  v-model.trim="utility_bill"
                  :placeholder="$t('upload_a_file_or_drag_files_here')"
                  drop-placeholder="Drop file here..."
                  ref="utility_bill"
                ></b-form-file>

                <b-row class="col-md-6 mt-1 mr-0 pr-0">
                  <b-alert show variant="info" class="col-md-12">
                    <b-row class="col-md-12">
                      <b-col
                        class="col-md-12 mt-2 mb-2 text-center pointer"
                        @click="triggerUploadFile('utility_bill')"
                      >
                        <div>
                          <feather-icon icon="UploadIcon" size="25" />
                          <span></span>
                          {{ $t("upload_a_file_or_drag_files_here") }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-alert>
                </b-row>
              </div>
              <div v-else>
                <span class="uploaded-text"
                  >{{ $t("uploaded") }}
                  <feather-icon icon="CheckIcon"></feather-icon>
                </span>
              </div>
            </div>
          </b-row>
        </div>
        <div
          class="mt-1"
          v-if="errors.length > 0"
          v-for="item in errors"
          :key="item"
        >
          <div class="alert alert-danger">
            <div class="col-md-12 p-2">
              {{ item }}
            </div>
          </div>
        </div>
        <b-row class="mt-2 mb-1" >
          <b-col class="col-md-12" align="end">
            <b-button variant="primary" @click="uploadFiles">{{
              $t("save")
            }}</b-button>
          </b-col>
        </b-row>
      </div>
    </b-card-text>
    <div class="col-12" v-if="showLoader">
      <img src="/new-loader.svg" />
    </div>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { VueAutosuggest } from "vue-autosuggest";
import snsWebSdk from '@sumsub/websdk';
import axios from 'axios';
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BFormDatepicker,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckbox,
  BAlert,
  BInputGroupPrepend,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
export default {
  name: "UploadIdentificationDocuments",
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormDatepicker,
    BFormRadio,
    BFormRadioGroup,
    BAlert,
    BInputGroupPrepend,
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
  },
  data() {
    return {
      passport_front: null,
      file_passport_front_old: null,
      file_passport_back_old: null,
      file_utility_bill_old: null,
      file_passport_back_included: false,
      passport_back: null,
      selfie: null,
      utility_bill: null,
      passportFrontFile: null,
      passportBackFile: null,
      selfieFile: null,
      utilityBillFile: null,
      passport_back_included_in_passport_front_file: null,
      code: null,
      files: [],
      errors: [],
      hideButton: true,
      showLoader: false,
      loader: false,
      sumsubToken: null,
    };
  },
  created() {
    axios.post(`https://api.sumsub.com/resources/applicants?levelName=basic-kyc-level`, {externalUserId: 'preview-random-t4rvyhrfd1'}
  ).then((res) => {
        if ( res.data) {
          console.log('res.dataaaa',res.data);
          
        }
       
      });
    this.$http.post("/sumsub/token?user_id=test2710test&level=kyb-level").then((res) => {
        if ( res.data) {
          console.log('res.data',res.data);
          this.sumsubToken = res.data.token;
        }
      });
    this.getUser();
  },
  methods: {
    launchWebSdk(accessToken, applicantEmail, applicantPhone, customI18nMessages) {
      accessToken =  this.sumsubToken;
    let snsWebSdkInstance = snsWebSdk.init(
            accessToken,
            () => this.getNewAccessToken()
        )
        .withConf({
            lang: 'en',
            email: applicantEmail,
            phone: applicantPhone,
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true})
        .on('idCheck.onStepCompleted', (payload) => {
            console.log('onStepCompleted', payload)
        })
        .on('idCheck.onError', (error) => {
            console.log('onError', error)
        })
        .build();
       snsWebSdkInstance.launch('#sumsub-websdk-container')
},
 getNewAccessToken() {
  this.$http.post("/sumsub/token?user_id=test2710test&level=kyb-level").then((res) => {
        if ( res.data) {
          this.sumsubToken = res.data.token;
        }
      });
  return Promise.resolve( this.sumsubToken)
},
    getUser() {
      this.showLoader = true;
      this.$http.post("/buyer/show", {}).then((res) => {
        if (res.data.value && res.data.value.identifiaction_documents) {
          console.log('true')
          this.hideButton = false;
          this.code = res.data.value.identifiaction_documents
            ? res.data.value.identifiaction_documents.id_code
            : "";

          this.documents = res.data.value.identifiaction_documents;
          this.file_passport_front_old = this.documents.passport_front;
          this.file_passport_back_old = this.documents.passport_back;
          this.file_utility_bill_old = this.documents.utility_bill;
     
          if (this.documents) {
            let parsed = {
              pass_back: this.documents.passport_back ? this.documents.passport_back.split("/") : null,
              pass_front: this.documents.passport_front ? this.documents.passport_front.split("/") : null,
              selfie:this.documents.selfie ? this.documents.selfie.split("/") : null,
              utility:this.documents.utility_bill ?  this.documents.utility_bill.split("/") : null,
            };
          

            this.passportBackFile = parsed.pass_back ? 
              parsed.pass_back[parsed.pass_back.length - 1] : null;
            this.passportFrontFile = parsed.pass_front ?
              parsed.pass_front[parsed.pass_front.length - 1]  : null;
            this.selfieFile =  parsed.selfie ? parsed.selfie[parsed.selfie.length - 1]  : null;
            this.utilityBillFile =parsed.utility ?
              parsed.utility[parsed.utility.length - 1]  : null;
          }
 
        }
        this.showLoader = false;
      });
    },
    alreadyIncludedInFrontFile() {
      this.$http.post(`/invoice/storeFiles`, formData, config).then((res) => {
        if (res) {
          this.passport_front = res.data.value;
        }
      });
    },
    removeFile(type, file) {
      if (type == "passport_front") {
        this.passportFrontFile = null;
        this.passport_front = null;
        this.file_passport_front_old = null;
      }
      if (type == "passport_back") {
        this.passportBackFile = null;
        this.passport_back = null;
        this.file_passport_back_old = null;
        console.log('removed')
      }
      if (type == "utility_bill") {
        this.utilityBillFile = null;
        this.utility_bill = null;
        this.file_utility_bill_old = null;
      }
      this.files.forEach((el, index) => {
        if (el.documentType == type) {
          this.files.splice(index, 1);
        }
      });
    },
    uploadFiles() {
      console.log('pass front',this.file_passport_back_old)
      let formData = new FormData();
      this.files.forEach((el) => {
        formData.append("file_" + el.documentType, el.el);
      });
      formData.append('file_passport_front_old',this.file_passport_front_old);
      formData.append('file_passport_back_old',this.file_passport_back_old);
      formData.append('file_utility_bill_old',this.file_utility_bill_old);
      if(this.file_passport_back_included){
        formData.append('file_passport_back_included',this.file_passport_back_included);
      }
      console.log('files',this.files)
      this.showLoader = true;
      let config = { headers: { "Content-Type": "multipart/form-data" } };

      this.$http
        .post(`/buyer/document/uploadMultiple`, formData, config)
        .catch((err) => {
          if (err) {
            this.errors.push(err.data.message);
            this.showLoader = false;
          }
        })
        .then((res) => {
          if (res.data.status != 500) {
            this.passportFrontFile = res.data.value.passport_front;
            this.passportBackFile = res.data.value.passport_back;
            this.selfieFile = res.data.value.selfie;
            this.utilityBillFile = res.data.value.utility_bill;
            this.showSuccessMessage();
            this.$router.push("/");
          }

          if (res.data.status == 500) {
            this.errors.push(res.data.message);
          }
          this.showLoader = false;
        });
    },
    showSuccessMessage() {
      let text = this.$t("success");
      this.$swal({
        position: "center",
        icon: "success",
        title: text,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    uploadFile(event, file, documentType) {
      event.target.files.forEach((el) => {
        this.files.push({ documentType, el });

        if (documentType == "passport_front") {
          this.passportFrontFile = el.name;
        }
        if (documentType == "passport_back") {
          this.passportBackFile = el.name;
        }
        if (documentType == "selfie") {
          this.selfieFile = el.name;
        }
        if (documentType == "utility_bill") {
          this.utilityBillFile = el.name;
        }
      });
    },
    triggerUploadFile(documentType) {
      this.$refs[documentType].$refs.input.click();
    },
  },
};
</script>

<style scoped>
.mandatory-documents-title {
  color: #1586c4;
  border-left: 2px solid #1586c4;
  padding-left: 8px;
  margin-bottom: 30px;
}
.mandatory-documents-passport-front {
  padding: 30px;
  background-color: #faf9f9;
}
.mandatory-documents-passport-back {
  padding: 30px;
  background-color: #faf9f9;
}
.mandatory-documents-passport-utility-bill {
  padding: 30px;
  background-color: #faf9f9;
}
.mandatory-documents-passport-selfie {
  padding: 30px;
  background-color: #ffffff;
}
.mandatory-documents-label {
  display: flex;
  justify-content: center;
}
.mandatory-documents-label > span {
  color: #1586c4;
  border-bottom: 1px solid #1586c4;
  padding-bottom: 4px;
  font-size: 18px;
}
.selfie-fundamentals {
  padding: 10px;
}
.uploaded-text {
  padding: 10px 30px;
  background-color: #55dd92;
  border-radius: 5px;
  color: white;
}
</style>
