<template>
  <b-card class="auth-wrapper auth-v2" no-body>
    <b-row
      v-if="!load"
      class="m-0 col-xl-12 col-lg-12 pr-0"
      style="min-height: 80vh"
    >
      <div class="pt-2 card-width-invoices">
        <b-col class="px-xl-2 mx-auto" style="min-height: 70vh">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            <h1 class="text-purple">{{ $t("upload_invoices") }} 👋</h1>
          </b-card-title>
          <div v-if="isLoading">
            <img src="/loader.svg" />
          </div>
          <b-card-text class="mb-2 mt-1">
            {{ $t("start_selling_your_initial_invoices") }}
            <br />
            {{ $t("upload_files") }} {{ pdfs.length }}/3 <br /><br />

            <div class="row mt-xl-5">
              <div
                class="col-xl-4 col-md-6 mt-xl-2"
                v-for="(item, index) in pdfs"
                :key="index"
              >
                <b-card class="text-center upload-card-invoice">
                  <!-- <div align="end" class="col-md-12 pointer" @click="removeInvoice(item)">
                     x
                    </div> -->
                  <template #footer>
                    <span
                      v-if="item.id"
                      class="light-blue pointer"
                      @click="downloadFile(item.id)"
                      >{{ $t("download") }}</span
                    >
                  </template>

                  <b-img
                    class="mt-2"
                    src="/images/file.svg"
                    v-bind="mainProps"
                    fluid
                    alt="Responsive image"
                  ></b-img>

                  <br />
                  <br />
                  <br v-if="!item.id" />

                  <b-card-text v-if="item.title">
                    {{ item.title.substring(0, 15) }}
                    <span v-if="item.length > 15">...</span>
                  </b-card-text>
                  <br v-if="!item.id" />
                
                </b-card>
                <!-- <div style="display: grid;">  
                  <b-button
                class="mt-1 web text-center"
                 variant="outline-success"
                size="sm"  >
                 Sell Invoice
               </b-button></div> -->
               
              </div>
            </div>
            <div
              class="col-xl-4 col-md-6 mt-xl-2 pl-0 pr-1"
              v-if="pdfs.length < 3"
            >
              <b-card class="text-center upload-card-invoice">
                <template #footer>
                  <small>
                    {{ $t("browse_file_and_add") }}
                  </small>
                </template>
                <b-img
                  class="mt-2"
                  src="/images/upload-file.svg"
                  v-bind="mainProps"
                  fluid
                  alt="Responsive image"
                ></b-img>
                <br />
                <br />
                <b-card-text>
                  {{ $t("upload_a_file_or_drag_files_here") }}
                </b-card-text>
                <b-form-file
                accept=".jpg, .jpeg, .png, .pdf"
                  multiple
                  @change="uploadFiles($event, 1, file1)"
                  v-model.trim="file1"
                  :placeholder="$t('upload_a_file_or_drag_files_here')"
                  drop-placeholder="Drop file here..."
                  ref="fileInput1"
                ></b-form-file>
                <br />
                
                <!-- <div v-else>
                    <span
                      class="col-md-12 pointer light-blue"
                      @click="showDetails(file1)"
                      >{{ $t("invoice_details") }}</span
                    >
                  </div> -->
              </b-card>
            </div>
          </b-card-text>
          
        </b-col>
        <div class="col-md-12 mb-3" align="end">
          <img v-if="resLoader" width="50" height="50" src="/new-loader.svg" />
          <b-button
            variant="primary"
            :disabled="pdfs.length < 3 || disabledSubmit"
            @click="uploadFile()"
            >{{ $t("submit") }}</b-button
          >
        </div>
      </div>
      <div class="mr-0 pr-0 card-width-image" aling="end">
        <b-img
          src="/images/invoices.png"
          fluid
          right
          style="height: 100%; object-fit: fill"
        ></b-img>
      </div>

      <!-- /Login-->
    </b-row>

    <div v-else>
      <img src="/new-loader.svg" />
    </div>
  </b-card>
</template>
<script>
var fileDownload = require("js-file-download");
const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

import { ValidationProvider, ValidationObserver } from "vee-validate";
import ComapnyModal from "./CompanyModal.vue";

export default {
  data() {
    return {
      disabledSubmit: false,
      resLoader: false,
      file1: null,
      file2: null,
      file3: null,
      pdfs: [],
      newFile: null,
      singleFile: null,
      files: [],
      filteredOptions: [],
      file1Title: "",
      file2Title: "",
      file3Title: "",
      hideFirstInput: false,
      hideSecondInput: false,
      hideThirdInput: false,
      hideInputs: false,
      load: false,
      eventFile: null,
      dialog: false,
      selectedFile: null,
      isLoading: false,
      invoiceId: "",
      lastOne: false,
      mainProps: {
        blank: false,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    ComapnyModal,
  },
  watch: {},
  created() {
    this.getInvoices();
  },
  methods: {
    removeInvoice(invoice, index) {
      this.pdfs.forEach((el) => {});
    },
    toggleDarkMode() {
      let color = "";
      if (this.$store.state.verticalMenu.lightMode == "light") {
        color = "background:white !important";
      } else {
        color = "background:#283046 !important";
      }
      return color;
    },
    closeModal() {
      this.dialog = false;
    },
    getAll() {
      this.lastOne = true;
      this.getInvoices();
    },
    getInvoices(type) {
      this.load = true;
      this.$http.post("/invoice/list").then((res) => {
        if (
          typeof res.data.value.data.data != "undefined" &&
          res.data.value.data.data.length > 0
        ) {
          this.pdfs = res.data.value.data.data;
          if (this.pdfs.length < 1) {
            this.file1Title = "";
            this.file2Title = "";
            this.file3Title = "";
            this.hideThirdInput = false;
            this.hideSecondInput = false;
            this.hideFirstInput = false;
          }
        }
        if (this.pdfs.length >= 3 && type == "afterUpload") {
          this.$store.commit("verticalMenu/SHOW_INVOCIES_SIDE_BAR", true);
          document.getElementById("updateSideBar").click();
          this.$router.push("/invoices");
        } else if (this.pdfs.length >= 3) {
          this.$router.push("/invoices");
        }
        this.load = false;
      });
    },
    downloadFile(id) {
      this.$http
        .post(
          "/invoice/download/" + id,
          {},
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          let parsed = response.data.type.split("/");
          let str = "doc." + parsed[1];
          fileDownload(response.data, str);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    openModal(event, type, file) {
      if (file) {
        this.selectedFile = type;
        this.eventFile = event;
        this.dialog = true;
      }
    },
    uploadFiles(event, file) {
      if (
        event.target.files.length > 3 ||
        this.pdfs.length > 3 ||
        this.pdfs.length + event.target.files.length > 3
      ) {
        this.pdfs = [];
        this.file1 = null;
        this.file1Title = "";
        let text = this.$t("only_3_files_allowed");
        this.$swal({
          position: "center",
          icon: "error",
          title: text,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-secondary",
          },
          buttonsStyling: false,
        });
       
      } else {
        event.target.files.forEach((el, index) => {
          let data = {
            title: el.name,
          };
          this.pdfs.push(data);
          this.files.push(el);
          this.isLoading = false;
          this.file1 = null;
        });
      }
    },
    async uploadFile() {
  this.resLoader = true;
  this.disabledSubmit = true;
  let config = { headers: { "Content-Type": "multipart/form-data" } };
  let formData = new FormData();

  this.files.forEach((el, index) => {
    formData.append("file" + index, el);
  });
  formData.append("length", this.files.length);

  try {
    const res = await this.$http.post(`/invoice/storeFiles`, formData, config);
    if (res.data.status == 200) {
      let text = this.$t("file_uploaded_successfully");
      await this.$swal({
        position: "center",
        icon: "success",
        title: text,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      window.location = '/';
    } else {
      let text = this.$t(res.data.message);
      await this.$swal({
        position: "center",
        icon: "error",
        title: text,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-secondary",
        },
        buttonsStyling: false,
      });
    }
  } catch (err) {
    let text = this.$t("only_pdf_files_allowed");
    await this.$swal({
      position: "center",
      icon: "error",
      title: text,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        confirmButton: "btn btn-secondary",
      },
      buttonsStyling: false,
    });
  } finally {
    this.resLoader = false;
    this.disabledSubmit = false;
  }
}
,
    uploadFile1(event, file) {
      this.eventFile = event;
      this.isLoading = true;
      if (event.target.files.length > 3 || this.pdfs.length > 3) {
        let text = this.$t("only_3_files_allowed");
        this.$swal({
          position: "center",
          icon: "error",
          title: text,
          showConfirmButton: false,
          timer: 1500,
        });

        setTimeout(() => {
          window.location.reload();
        }, 500);

        return "";
      } else {
        let config = { headers: { "Content-Type": "multipart/form-data" } };
        let formData = new FormData();

        event.target.files.forEach((el, index) => {
          formData.append("file" + index, el);
        });
        formData.append("length", event.target.files.length);
        //   formData.append("files", event.target.files);
        this.$http.post(`/invoice/storeFiles`, formData, config).then((res) => {
          if (res) {
            this.isLoading = false;
            let text = this.$t("file_uploaded_successfully");
            this.$swal({
              position: "center",
              icon: "success",
              title: text,
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            }).then((res) => {
              this.getInvoices("afterUpload");
            });
          }
        });
      }
    },
    showDetails(file) {
      this.singleFile = file;
      this.dialog = true;
    },
    addInvoice(event) {
      this.singleFile = null;
      this.selectedFile = null;
      let formData = new FormData();
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      formData.append("pdf", event.target.files[0]);

      this.$http.post("/invoice/store", formData, config).then((res) => {
        if (res) {
          this.invoiceId = res.data.value.id;
          let text = this.$t("file_uploaded_successfully");
          this.$swal({
            position: "center",
            icon: "success",
            title: text,
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((res) => {
            this.getInvoices();
          });
        }
      });
    },
  },
};
</script>
<style scoped>
</style>
