<template>
  <div>
    <AddCompany v-if="task === 'add-company'" />
    <UploadInvoices  v-if="task === 'upload-invoices'" />
    <UploadDocs  v-if="task === 'upload-docs'" />
    <FillStep1 v-if="task === 'fill-online-form'" />
    <CompleteIdentification v-if="task === 'complete-identification'"></CompleteIdentification>
    <AddBuyer v-if="task === 'add-buyer'"></AddBuyer>
    <TransferSymbolicAmount v-if="task === 'transfer-symbolic-amount'"></TransferSymbolicAmount>
    <ActivateAccount v-if="task === 'activate-account'"></ActivateAccount>

    <ConfirmBeneficialOwner v-if="task === 'confirm-beneficial-owner'"></ConfirmBeneficialOwner>
    <UploadIdentificationDocuments v-if="task === 'upload-identification-documents'"></UploadIdentificationDocuments>

  </div>
</template>

<script>



import AddCompany from "@/components/cookpit/AddCompany";
import UploadInvoices from "@/components/cookpit/UploadInvoices.vue";
import UploadDocs from "@/components/cookpit/UploadDocs.vue";
import CompleteIdentification from '@/components/cookpit/CompleteIdentification.vue'
import AddBuyer from "@/components/buyer-cookpit/CookpitAddBuyer";
import TransferSymbolicAmount from "@/components/buyer-cookpit/TransferSymbolicAmount";
import ActivateAccount from "@/components/buyer-cookpit/ActivateAccount";
import ConfirmBeneficialOwner from "@/components/buyer-cookpit/ConfirmBeneficialOwner";
import UploadIdentificationDocuments from "@/components/buyer-cookpit/UploadIdentificationDocuments";
import FillStep1 from "../../components/cookpit/OnlineForm/FillStep1";


export default {
  components: {
    UploadIdentificationDocuments,
    ConfirmBeneficialOwner,
    ActivateAccount,
    TransferSymbolicAmount,
    AddBuyer,
    AddCompany,
    UploadInvoices,
    UploadDocs,
    CompleteIdentification,
    FillStep1
  },
  data() {
    return {
      task: this.$route.params.task,
    }
  },
  created() {
  },
  methods: {
  },
}
</script>

<style>

</style>
